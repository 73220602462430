import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../../components/layout';
import styles from './benefits.module.scss';
import HeroImage from '../../components/hero-image/hero-image';
import BenefitsOverviewSection from '../../components/travel-nursing/benefits/benefits-overview-section';
import BenefitsInsuranceSection from '../../components/travel-nursing/benefits/benefits-insurance-section';
import BenefitsHousingSection from '../../components/travel-nursing/benefits/benefits-housing-section';
import BenefitsRecruiterSection from '../../components/travel-nursing/benefits/benefits-recruiter-section';
import { Helmet } from 'react-helmet';

export default ({ data }) => {
  const getTravelNursingBenefitsImageEdge = name => {
    return data.allFile.edges.find(e => e.node.name === name);
  };
  const ogImageUrlPath = getTravelNursingBenefitsImageEdge(
    'travel-nursing-benefits-og'
  ).node.childImageSharp.fluid.src;
  const fullOgImageUrl = `https://www.trustaff.com${ogImageUrlPath}`;
  return (
    <>
      <Helmet>
        <title>Travel nursing benefits - Trustaff</title>
        <meta charSet="utf-8" />
        <meta
          name="Description"
          content="When you become a Trustaff travel nurse, you have access to a wide range of customizable benefits options including health insurance, dental, vision, 401k, and more."
        />
        <meta property="og:title" content="Travel nursing benefits" />
        <meta
          property="og:description"
          content="When you become a Trustaff travel nurse, you have access to a wide range of customizable benefits options including health insurance, dental, vision, 401k, and more."
        />
        <meta property="og:image" content={fullOgImageUrl} />
        <meta
          property="og:url"
          content="https://www.trustaff.com/travel-nursing/benefits/"
        />
        <meta property="og:locale" content="en_US" />
        <meta property="og:site_name" content="Trustaff" />
        <meta property="og:type" content="website" />
        <meta name="twitter:site" content="@Trustaff" />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <Layout>
        <HeroImage
          fluid={
            getTravelNursingBenefitsImageEdge('travel-nursing-benefits').node
              .childImageSharp.fluid
          }
        >
          <div className={'columns'}>
            <div className="column is-7 is-offset-1">
              <div className={styles.heroTextArea}>
                <h1 className={`title is-2 has-text-white`}>
                  Travel nursing benefits
                </h1>
                <p className={`content`}>
                  What’s almost as important as finding a great job? Affordable
                  benefits for you and your family. When you become a Trustaff
                  travel nurse, you have access to a wide range of customizable
                  benefits options including health insurance that starts at
                  just <b>$24/week!</b>
                </p>
                <p className={`content`}>
                  It's important to have peace of mind when it comes to your
                  health and well-being. Trustaff offers comprehensive travel
                  nursing benefits that protect both you and your
                  family—including the power to choose which plans meet your
                  needs.
                </p>
              </div>
            </div>
          </div>
        </HeroImage>
        <div className={`section ${styles.sectionNoSidePaddingMobile}`}>
          <BenefitsOverviewSection
            fluid={
              getTravelNursingBenefitsImageEdge('traveling-nurse-pet').node
                .childImageSharp.fluid
            }
          />
        </div>
        <div
          className={`section ${styles.sectionNoSidePaddingMobile} ${styles.sectionBackgroundGrey}`}
        >
          <BenefitsInsuranceSection
            fluid={
              getTravelNursingBenefitsImageEdge('using-computer').node
                .childImageSharp.fluid
            }
          />
        </div>
        <div className={`section ${styles.sectionNoSidePaddingMobile}`}>
          <BenefitsHousingSection
            fluid={
              getTravelNursingBenefitsImageEdge('travel-nurse-housing').node
                .childImageSharp.fluid
            }
          />
        </div>
        <div
          className={`section ${styles.sectionNoSidePaddingMobile} ${styles.sectionBackgroundGrey}`}
        >
          <BenefitsRecruiterSection
            fluid={
              getTravelNursingBenefitsImageEdge('trustaff_recruiter').node
                .childImageSharp.fluid
            }
          />
        </div>
      </Layout>
    </>
  );
};

export const pageQuery = graphql`
  {
    allFile(filter: { relativePath: { regex: "/^travel-nursing-benefits/" } }) {
      edges {
        node {
          name
          childImageSharp {
            fluid(maxWidth: 2400) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;
