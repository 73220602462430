import React from 'react';
import Img from 'gatsby-image';
import styles from './benefits-recruiter-section.module.scss';

export default ({ fluid }) => {
  return (
    <div className="columns">
      <div
        className={`columns column is-12 is-10-desktop is-offset-1-desktop has-text-centered-mobile`}
      >
        <div className="column is-6">
          <h3 className="title is-5 has-text-info">
            Relationships built on trust
          </h3>
          <div className="content">
            <p className={`content`}>
              We pride ourselves on the personal relationships we build with
              each traveler year after year. It’s our job to learn about
              you—what you like, what you want, what you need, what your pets
              names are—and then tailor a benefits package that will deliver
              exactly what you need.
            </p>
            <p className={`content`}>
              At Trustaff, it‘s our mission to go above and beyond to make your
              travel nursing experience a positive one. Your recruiter is there
              to offer guidance throughout the entire process, from job
              interview tips to scheduling your next assignment around your
              family visit or dream vacation.
            </p>
          </div>
        </div>
        <div
          className={`column is-5 is-offset-1 ${styles.noSidePaddingMobile}`}
        >
          <Img
            fluid={fluid}
            className={styles.aboutImageWrapper}
            alt="travel nursing help"
          />
        </div>
      </div>
    </div>
  );
};
