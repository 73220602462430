import React from 'react';
import { Link } from 'gatsby';
import Img from 'gatsby-image';
import styles from './benefits-overview-section.module.scss';

export default ({ fluid }) => {
  return (
    <div className="columns">
      <div
        className={`columns column is-12 is-10-desktop is-offset-1-desktop has-text-centered-mobile`}
      >
        <div className="column is-6">
          <h3 className="title is-5 has-text-info">
            Jobs you want—with the benefits you and your family deserve
          </h3>
          <div className="content">
            <p className="content">
              Not only do we have a huge selection of travel nursing jobs at
              some of the country’s top facilities, Trustaff travelers also
              enjoy great employee benefits and one-on-one customer service.
              Here are just a few of the top-tier benefits you’ll enjoy as a
              Trustaff traveler:
            </p>
            <ul className={`is-size-7`}>
              <li>
                Health insurance options for you and your family including
                medical, dental, vision, short/long-term disability coverage,
                and more
              </li>
              <li>401k profit sharing plan with employer match</li>
              <li>
                <Link to="/travel-nursing/license-reimbursement/">
                  Nursing license reimbursement
                </Link>{' '}
                program
              </li>
              <li>
                Career tools, professional advocacy, and wellness resources
                through the Ingenovis Health{' '}
                <Link to="/ACTprogram/">ACT program</Link>{' '}
              </li>
              <li>
                Exclusive{' '}
                <Link to="/perks-discounts/">
                  employee discounts
                </Link>
              </li>
              <li>Assistance with housing and travel</li>
              <li>
                Personal assistance throughout each step of your assignment
              </li>
              <li>24/7/365 support line</li>
              <li>
                Plus, earn great{' '}
                <Link to="/travel-nursing/pay-bonuses/">
                  travel nursing bonuses
                </Link>
                !
              </li>
            </ul>
          </div>
          <Link to="/apply/" className="button is-primary">
            Get started
          </Link>
        </div>
        <div
          className={`column is-5 is-offset-1 ${styles.noSidePaddingMobile}`}
        >
          <Img
            fluid={fluid}
            className={styles.aboutImageWrapper}
            alt="travel nursing benefits"
          />
        </div>
      </div>
    </div>
  );
};
