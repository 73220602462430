import React from 'react';
import Img from 'gatsby-image';
import styles from './benefits-housing-section.module.scss';

export default ({ fluid }) => {
  return (
    <div className="columns">
      <div
        className={`columns column is-12 is-10-desktop is-offset-1-desktop has-text-centered-mobile`}
      >
        <div className="column is-6">
          <h3 className="title is-5 has-text-info">Travel and housing</h3>
          <div className="content">
            <p className={`content`}>
              When considering a new travel nursing assignment, one of the first
              questions we usually get is, "Where will I stay?"
            </p>
            <p className={`content`}>
              Trustaff has a department dedicated to assisting our travelers
              with their travel and housing needs. Whether you want to rent a
              fully furnished apartment, stay in a nearby hotel, or even bring
              your home-away-from-home with you to an RV park, there are a wide
              range of options for every budget and comfort level.
            </p>
            <p className="content">
              If you prefer to make your own housing arrangements, that's fine
              too! We simply pay you a housing per diem in your weekly paycheck,
              which you can then use to offset your housing costs.
            </p>
            <p className={`content`}>
              Housing options vary greatly from assignment to assignment, based
              on the local area, the cost of living, and any government
              limitations.
            </p>
          </div>
        </div>
        <div
          className={`column is-5 is-offset-1 ${styles.noSidePaddingMobile}`}
        >
          <Img
            fluid={fluid}
            className={styles.aboutImageWrapper}
            alt="travel nursing housing"
          />
        </div>
      </div>
    </div>
  );
};
